@mixin base_dark {
  color: $fg-color-dark;
  background-color: $bg-color-dark;

  a {
    color: $link-color-dark;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $alt-fg-color-dark;

    &:hover .heading-link {
      visibility: visible;
    }

    .heading-link {
      color: $link-color-dark;
      font-weight: inherit;
      text-decoration: none;
      font-size: 80%;
      visibility: hidden;
    }

    .title-link {
      color: inherit;
      font-weight: inherit;
      text-decoration: none;
    }
  }

  pre code {
    background-color: inherit;
    color: inherit;
  }

  code {
    background-color: $lighter-alt-bg-color-dark;
    color: $fg-color-dark;
  }

  blockquote {
    border-left: 2px solid $alt-bg-color-dark;
  }

  th,
  td {
    padding: 1.6rem;
  }

  table {
    border-collapse: collapse;
  }

  table td,
  table th {
    border: 2px solid $alt-fg-color-dark;
  }

  table tr:first-child th {
    border-top: 0;
  }

  table tr:last-child td {
    border-bottom: 0;
  }

  table tr td:first-child,
  table tr th:first-child {
    border-left: 0;
  }

  table tr td:last-child,
  table tr th:last-child {
    border-right: 0;
  }

  .citation-box {
    position: relative;
    margin-bottom: 20px;
  }
  
  .textarea-container {
    position: relative;
    margin-bottom: 20px;
  }

  .textarea-container textarea {
    width: calc(100% - 20px); /* Adjust width to prevent overlap with button */
    padding: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
    box-sizing: border-box;
    border-radius: 4px;
    background-color: $cite-bg-color-dark;
    color: $cite-fg-color-dark;
    border: 1px solid $cite-border-color-dark;
    resize: none;
    font-family: monospace;
  }

  .textarea-container button {
    position: absolute;
    top: 10px;
    right: 25px;
    padding: 5px 10px;
    font-size: 0.9em;
    border: none;
    border-radius: 4px;
    background-color: $cite-button-bg-color-dark;
    color: $cite-button-fg-color-dark;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .textarea-container button:hover {
    background-color: $button-hover-bg-color-dark;
  }

  /* Base styles for the summary box */
  .summary-box {
    margin: 20px 0;
    padding: 20px;
    font-style: italic;
    background-color: $summary-bg-color-dark;
    border-left: 5px solid $summary-border-color-dark;
    color: $summary-color-dark;
  }

  .summary-box p {
    margin: 0;
  }

}

body.colorscheme-dark {
  @include base_dark();
}

body.colorscheme-auto {
  @media (prefers-color-scheme: dark) {
    @include base_dark();
  }
}



// OLD VERSION

// .citation-box {
//   position: relative;
//   margin-bottom: 20px;
// }

// .citation-box textarea {
//   width: 100%;
//   height: 50px;
//   padding: 10px;
//   margin-top: 5px;
//   margin-bottom: 10px;
//   box-sizing: border-box;
//   border: 1px solid #ccc;
//   border-radius: 4px;
//   background-color: #212328;
//   color: #ececec;
//   resize: none;
//   font-family: monospace;
// }

// .citation-box button {
//   padding: 10px 15px;
//   font-size: 0.9em;
//   border: 1px solid #ccc;
//   border-radius: 4px;
//   background-color: #3d4454;
//   color: white;
//   cursor: pointer;
//   transition: background-color 0.3s ease;
// }

// .citation-box button:hover {
//   background-color: #1d3d5f;
// }