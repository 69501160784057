@mixin taxonomy_dark {
  .taxonomy-element {
    background-color: $alt-bg-color-dark;

    a {
      color: $fg-color-dark;
    }
    a:active {
      color: $fg-color-dark;
    }
  }
}

body.colorscheme-dark {
  @include taxonomy_dark();
}

body.colorscheme-auto {
  @media (prefers-color-scheme: dark) {
    @include taxonomy_dark();
  }
}
